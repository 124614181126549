.parallax {
    background-image: url("../images/mosaico.jpg");
    height: 100vh;
    /* Altura total da janela */
    overflow-y: auto;
    overflow-x: auto;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Centraliza horizontalmente */
    position: relative;
    max-width: 100vw;
}

@media (max-width: 768px) {
    .parallax {
        background-attachment: scroll;
        height: auto;
        padding: 60px 0;
    }
}

.parallax .container {
    max-width: 1200px;
    /* Limita a largura do conteúdo */
    width: 100%;
    margin: 0 auto;
    /* Centraliza o container */
}

.translucent-card {
    background-color: rgba(0, 0, 0, 0.7) !important;
    /* Fundo preto semi-transparente */
    border: none;
    border-radius: 15px;
    padding: 20px;
    color: #fff !important;
    /* Texto branco */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.7);
    width: 100%;
    /* O card ocupará 100% da largura do container */
    max-width: 1200px;
    /* Largura máxima para o card */
}
